import Vue from "vue";
import Vuex from "vuex";
import { createLogger } from "vuex";
import { CONTRACTS, DRAFT_CONTRACTS } from "../managrx/constants";
import {
  getASByID,
  getDraftASByID,
  makeSingleActivitySliceInfoGrid,
  makeSingleDraftActivitySliceInfoGrid,
  kRowsToItems,
  getSliceProductsList,
  updateSingleActivitySliceCache,
  updateSingleDraftActivitySliceCache,
} from "../managrx/services.js";
import {
  retrieveCampaignMultiResourceUtilization,
  retrieveDraftMultiResourceUtilization,
  // retrieveResourceUtilization,
  retrieveMultiResourceUtilization,
} from "../managrx/resourceUtilization";
import {
  formatRowsToLocaleString,
  parseToLocaleString,
} from "../managrx/utils.js";
import { getRequest, putRequest } from "../managrx/serverCall";
import { checkNotificationStructure } from "../managrx/errorHandler.js";

Vue.use(Vuex);

function processViewObject(viewObject) {
  viewObject.rows = viewObject.rows.map((row) =>
    row.map((value) => {
      if (typeof value === "number") {
        return value.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      } else {
        return value;
      }
    })
  );
  return viewObject;
}

export default new Vuex.Store({
  plugins: [createLogger()],
  state: {
    sideBarDrawer: false,
    singleEngagementSummary: {},
    notificationsQueue: [],
    views: [],
    viewObject: {},
    userTokenInfo: {},
    isUserLog: false,
    summary_by_activity: { rows: [], keys: [] },
    summary_by_region: { rows: [], keys: [] },
    simFactors: { rows: [], keys: [] },
    forwardPrices: { rows: [], keys: [] },
    campaignProductsList: null,
    sliceProductsList: null,
    availableViewsLoading: false,
    viewObjectLoading: false,
    summariesLoading: false,
    activitySlices: [],
    draftActivitySlices: [],
    campaignEngagements: { key: [], items: [] },
    draftEngagements: { key: [], items: [] },
    singleActivitySlice: {},
    singleDraftActivitySlice: {},
    singleDraftActivitySliceCashflow: {},
    isPageLoading: false,
    sliceInputCostPeriods: [],
    establishments: [],
    singleEstablishment: {},
    regions: [],
    activities: [],
    species: [],
    campaigns: [],
    singleAbstractCampaign: {},
    abstractCampaigns: [],
    campaignActivities: [],
    campaignActivitySlices: [],
    aggEngagementT3Metrics: [],
    aggDraftEngagementT3Metrics: [],
    engagementActivitySlices: [],
    draftEngagementActivitySlices: [],
    activityPricesFromCampaign: [],
    loadingQueueStatus: 0,
    campaignModifiers: [],
    filteredCampaignModifiers: [],
    yieldModifiers: [],
    priceModifiers: [],
    costItemFamilyModifiers: [],
    freightModifiers: [],
    rentModifiers: [],
    singleCampaign: {},
    singleEngagement: {},
    singleDraftEngagement: {},
    singleDraftEngagementMetrics: {},
    singleEngagementMetrics: {},
    homepageMetrics: {},
    directSliceCostItems: [],
    costItemFamilies: [],
    costItemGroups: [],
    costItemGroupElements: [],
    financialPolicies: [],
    interestRates: [],
    otherTonCostItems: [],
    singleActivitySliceEconomicCashflow: {},
    activitySlicesEconomicCashflow: [],
    singleActivitySliceCashflow: {},
    engagementCashflow: {},
    campaignCashflow: {},
    campaignMetrics: {},
    campaignSimulationMetrics: {},
    draftEngagementCashflow: {},
    engagementResourceUtilization: {},
    draftEngagementResourceUtilization: {},
    campaignResourceUtilization: {},
    draftActivitySlice: {},
    draftEngagementsToCompare: [],
    isDraftEngagementCompareActive: false,
    t1MetricsComparison: [],
    t4MetricsComparison: [],
    t3MetricsComparison: [],
    campaignVersionsToCompare: [],
    t1MetricsCampaignComparison: [],
    t4MetricsCampaignComparison: [],
    t3MetricsCampaignComparison: [],
    cartesianProductTerms: {},
    allUsers: [],
    users: [],
    managers: [],
    directors: [],
  },
  getters: {
    allManagers: (state) => {
      return state.managers;
    },
    allDirectors: (state) => {
      return state.directors;
    },
    users: (state) => {
      return state.users;
    },
    userTokenInfo: (state) => {
      return state.userTokenInfo;
    },
    campaignActivities: (state) => {
      return state.campaignActivities;
    },
    campaignActivitySlices: (state) => {
      return state.campaignActivitySlices;
    },
    aggEngagementT3Metrics: (state) => {
      return state.aggEngagementT3Metrics;
    },
    aggDraftEngagementT3Metrics: (state) => {
      return state.aggDraftEngagementT3Metrics;
    },
    engagementActivitySlices: (state) => {
      return state.engagementActivitySlices;
    },
    draftEngagementActivitySlices: (state) => {
      return state.draftEngagementActivitySlices;
    },
    activityPricesFromCampaign: (state) => {
      return state.activityPricesFromCampaign;
    },
    notificationsQueue: (state) => {
      return state.notificationsQueue;
    },
    t1MetricsComparison: (state) => {
      return state.t1MetricsComparison;
    },
    t4MetricsComparison: (state) => {
      return state.t4MetricsComparison;
    },
    t3MetricsComparison: (state) => {
      return state.t3MetricsComparison;
    },
    t1MetricsCampaignComparison: (state) => {
      return state.t1MetricsCampaignComparison;
    },
    t4MetricsCampaignComparison: (state) => {
      return state.t4MetricsCampaignComparison;
    },
    t3MetricsCampaignComparison: (state) => {
      return state.t3MetricsCampaignComparison;
    },
    isDraftEngagementCompareActive: (state) => {
      return state.isDraftEngagementCompareActive;
    },
    draftEngagementsToCompare: (state) => {
      return state.draftEngagementsToCompare;
    },
    campaignVersionsToCompare: (state) => {
      return state.campaignVersionsToCompare;
    },
    singleEngagementSummary: (state) => {
      return state.singleEngagementSummary;
    },
    sideBarDrawer: (state) => {
      return state.sideBarDrawer;
    },
    availableViews: (state) => {
      return state.views;
    },
    isAvailableViewsLoading(state) {
      return state.availableViewsLoading;
    },
    isViewObjectLoading(state) {
      return state.viewObjectLoading;
    },
    isSummaryLoading(state) {
      return state.summariesLoading;
    },
    viewObject(state) {
      return state.viewObject;
    },
    summaryByRegion(state) {
      return state.summary_by_region;
    },
    summaryByActivity(state) {
      return state.summary_by_activity;
    },
    summaryByBusinessActivity(state) {
      return state.summary_by_activity;
    },
    simulationFactors(state) {
      return state.simFactors;
    },
    forwardPrices(state) {
      return state.forwardPrices;
    },
    activitySlices(state) {
      return state.activitySlices;
    },
    draftActivitySlices(state) {
      return state.draftActivitySlices;
    },
    campaignModifiers(state) {
      return state.campaignModifiers;
    },
    filteredCampaignModifiers(state) {
      return state.filteredCampaignModifiers;
    },
    campaignEngagements(state) {
      return state.campaignEngagements;
    },
    yieldModifiers(state) {
      return state.yieldModifiers;
    },
    priceModifiers(state) {
      return state.priceModifiers;
    },
    costItemFamilyModifiers(state) {
      return state.costItemFamilyModifiers;
    },
    freightModifiers(state) {
      return state.freightModifiers;
    },
    rentModifiers(state) {
      return state.rentModifiers;
    },
    draftEngagements(state) {
      return state.draftEngagements;
    },
    singleActivitySlice(state) {
      return state.singleActivitySlice;
    },
    singleDraftActivitySlice(state) {
      return state.singleDraftActivitySlice;
    },
    singleDraftActivitySliceCashflow(state) {
      return state.singleDraftActivitySliceCashflow;
    },
    singleActivitySliceCashflow(state) {
      return state.singleActivitySliceCashflow;
    },
    isPageLoading(state) {
      return state.isPageLoading;
    },
    establishments(state) {
      return state.establishments;
    },
    singleEstablishment(state) {
      return state.singleEstablishment;
    },
    regions(state) {
      return state.regions;
    },
    otherTonCostItems(state) {
      return state.otherTonCostItems;
    },

    sliceCostItems(state) {
      let singleActivitySlice = state?.singleActivitySlice;
      let assignedSCostItems = singleActivitySlice.assignedSCostItems;
      let keys = [
        { text: "itemCost", value: "itemCost" },
        { text: "unitsPerHectare", value: "unitsPerHectare" },
        { text: "price", value: "price" },
        { text: "costItemNaturalOrder", value: "costItemNaturalOrder" },
        { text: "Fecha de uso", value: "dateUsed" },
        { text: "Nombre", value: "directSliceCostItemName" },
        { text: "Unidad de Medida", value: "unitMeasure" },
        { text: "periodType", value: "periodType" },
        { text: "percentApplication", value: "percentApplication" },
        { text: "Familia de Elemento de Costo", value: "costItemFamilyName" },
      ];
      return {
        keys: keys,
        rows: assignedSCostItems,
      };
    },
    draftSliceCostItems(state) {
      let singleDraftActivitySlice = state?.singleDraftActivitySlice;
      let assignedSCostItems = singleDraftActivitySlice.assignedSCostItems;
      let keys = [
        { text: "itemCost", value: "itemCost" },
        { text: "unitsPerHectare", value: "unitsPerHectare" },
        { text: "price", value: "price" },
        { text: "costItemNaturalOrder", value: "costItemNaturalOrder" },
        { text: "Fecha de uso", value: "dateUsed" },
        { text: "Nombre", value: "directSliceCostItemName" },
        { text: "Unidad de Medida", value: "unitMeasure" },
        { text: "periodType", value: "periodType" },
        { text: "percentApplication", value: "percentApplication" },
        { text: "Familia de Elemento de Costo", value: "costItemFamilyName" },
      ];
      return {
        keys: keys,
        rows: assignedSCostItems,
      };
    },
    singleActivitySliceInfoGrid(state) {
      try {
        let infoG = makeSingleActivitySliceInfoGrid(
          state?.singleActivitySliceCashflow
        );
        return infoG;
      } catch (error) {
        console.error(
          `ERROR: makeSingleActivitySliceInfoGrid
          ${error}
          `
        );
        return;
      }
    },
    singleDraftActivitySliceInfoGrid(state) {
      try {
        return makeSingleDraftActivitySliceInfoGrid(
          state?.singleDraftActivitySliceCashflow
        );
      } catch (error) {
        console.error(
          `ERROR: makeSingleDraftActivitySliceInfoGrid
          ${error}
          `
        );
      }
    },
    campaignProductsList(state) {
      return state?.campaignProductsList;
    },
    sliceProductsList(state) {
      return state?.sliceProductsList;
    },
    sliceInputCostPeriods(state) {
      return state.sliceInputCostPeriods;
    },
    activities(state) {
      return state.activities;
    },
    species(state) {
      return state.species;
    },
    singleAbstractCampaign(state) {
      return state.singleAbstractCampaign;
    },
    abstractCampaigns(state) {
      return state.abstractCampaigns;
    },
    campaigns(state) {
      return state.campaigns;
    },
    singleCampaign(state) {
      return state.singleCampaign;
    },
    singleEngagement(state) {
      return state.singleEngagement;
    },
    singleDraftEngagement(state) {
      return state.singleDraftEngagement;
    },
    singleDraftEngagementMetrics(state) {
      return state.singleDraftEngagementMetrics;
    },
    singleEngagementMetrics(state) {
      return state.singleEngagementMetrics;
    },
    directSliceCostItems(state) {
      return state.directSliceCostItems;
    },

    costItemFamilies(state) {
      return state.costItemFamilies;
    },
    costItemGroups(state) {
      return state.costItemGroups;
    },
    costItemGroupElements(state) {
      return state.costItemGroupElements;
    },
    variableShareCropSchedule(state) {
      return state.singleActivitySlice.variableShareCropSchedule;
    },
    draftVariableShareCropSchedule(state) {
      return state.singleDraftActivitySlice.variableShareCropSchedule;
    },
    financialPolicies(state) {
      return state.financialPolicies;
    },
    interestRates(state) {
      return state.interestRates;
    },
    activitySlicesEconomicCashflow(state) {
      return state.activitySlicesEconomicCashflow;
    },
    engagementCashflow(state) {
      return state.engagementCashflow;
    },
    campaignCashflow(state) {
      return state.campaignCashflow;
    },
    campaignMetrics(state) {
      return state.campaignMetrics;
    },
    campaignSimulationMetrics(state) {
      return state.campaignSimulationMetrics;
    },
    draftEngagementCashflow(state) {
      return state.draftEngagementCashflow;
    },
    engagementResourceUtilization(state) {
      return state.engagementResourceUtilization;
    },
    draftEngagementResourceUtilization(state) {
      return state.draftEngagementResourceUtilization;
    },
    campaignResourceUtilization(state) {
      return state.campaignResourceUtilization;
    },
    cartesianProductTerms(state) {
      return state.cartesianProductTerms;
    },
    homepageMetrics(state) {
      return state.homepageMetrics;
    },
  },
  mutations: {
    UPDATE_LOADING_STATUS(state, loadingStatus) {
      /* 
      loadingQueueStatus==0 -> not loading
      loadingQueueStatus>=1  -> loading 
      */
      state.loadingQueueStatus += loadingStatus;
      console.log("loading Status Q", state.loadingQueueStatus);
      if (state.loadingQueueStatus == 0) {
        state.isPageLoading = false;
      } else if (state.loadingQueueStatus > 0) {
        state.isPageLoading = true;
      }
    },
    SET_ALL_MANAGERS(state, managers) {
      state.managers = managers;
    },
    SET_ALL_DIRECTORS(state, directors) {
      state.directors = directors;
    },
    SET_ALL_USERS(state, users) {
      state.allUsers = users;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_USER_IS_LOGG(state, isUserLog) {
      state.isUserLog = isUserLog;
    },
    SET_USER_TOKEN_INFO(state, userTokenInfo) {
      state.userTokenInfo = userTokenInfo;
    },
    SET_CAMPAIGN_ACTIVITIES(state, activities) {
      state.campaignActivities = activities;
    },
    SET_CAMPAIGN_ACTIVITY_SLICES(state, activitySlices) {
      state.campaignActivitySlices = activitySlices;
    },
    SET_AGG_ENGAGEMENT_T3_METRICS(state, aggEngagementT3Metrics) {
      state.aggEngagementT3Metrics = aggEngagementT3Metrics;
    },
    SET_AGG_DRAFT_ENGAGEMENT_T3_METRICS(state, aggDraftEngagementT3Metrics) {
      state.aggDraftEngagementT3Metrics = aggDraftEngagementT3Metrics;
    },
    SET_ENGAGEMENT_ACTIVITY_SLICES(state, activitySlices) {
      state.engagementActivitySlices = activitySlices;
    },
    SET_ACTIVITY_PRICES_FROM_CAMPAIGN(state, activityPrices) {
      state.activityPricesFromCampaign = activityPrices;
    },
    SET_ENGAGEMENTS(state, engagements) {
      state.engagements = engagements;
    },
    SET_ENGAGEMENT_SUMMARY(state, engagementSummary) {
      state.singleEngagementSummary = engagementSummary;
    },
    TOGGLE_SIDEBAR_DRAWER(state) {
      state.sideBarDrawer = !state.sideBarDrawer;
    },
    SET_VIEWS(state, views) {
      state.views = views;
    },
    SET_VIEW_OBJECT(state, viewObject) {
      state.viewObject = viewObject;
    },
    ADD_NOTIFICATION_TO_QUEUE(state, notification) {
      console.log("Notifications!", state.notificationsQueue);
      if (state.notificationsQueue.length == 5) {
        console.log(
          "Max notifications reach!",
          state.notificationsQueue.length
        );
        state.notificationsQueue.pop();
        console.log("Notifications fix!", state.notificationsQueue.length);
      }
      state.notificationsQueue.unshift(notification);
      console.log("Notifications!", state.notificationsQueue);
    },
    DELETE_NOTIFICATION_FROM_QUEUE(state, id) {
      console.log("removing notification with id ", id);
      state.notificationsQueue = state.notificationsQueue.filter(
        (el) => el.id != id
      );
      console.log(state.notificationsQueue);
    },
    SET_SUMMARY_BY_ACTIVITY(state, viewObject) {
      state.summary_by_activity = viewObject;
    },
    SET_SUMMARY_BY_REGION(state, viewObject) {
      state.summary_by_region = viewObject;
    },
    SET_SIM_FACTORS(state, simFactors) {
      state.simFactors = simFactors;
    },
    SET_FORWARD_PRICES(state, forwardPrices) {
      state.forwardPrices = forwardPrices;
    },
    SET_ACTIVITY_SLICES(state, activitySlices) {
      state.activitySlices = activitySlices;
    },
    SET_DRAFT_ACTIVITY_SLICES(state, draftActivitySlices) {
      state.draftActivitySlices = draftActivitySlices;
    },
    SET_DRAFT_ENGAGEMENT_ACTIVITY_SLICES(state, draftActivitySlices) {
      state.draftEngagementActivitySlices = draftActivitySlices;
    },
    SET_DRAFT_ENGAGEMENTS(state, draftEngagements) {
      state.draftEngagements = draftEngagements;
    },
    CLEAR_DRAFT_ENGAGEMENTS(state) {
      state.draftEngagements = { key: [], items: [] };
    },
    CLEAR_DRAFT_ENGAGEMENT(state) {
      state.draftEngagement = {};
      state.draftEngagementSummary = { key: [], items: [] };
    },
    CLEAR_ENGAGEMENTS(state) {
      state.engagements = { key: [], items: [] };
    },
    CLEAR_ENGAGEMENT(state) {
      state.engagement = {};
      state.engagementSummary = { key: [], items: [] };
    },
    SET_CAMPAIGN_ENGAGEMENTS(state, campaignEngagements) {
      state.campaignEngagements = campaignEngagements;
    },
    SET_SINGLE_ACTIVITY_SLICE(state, singleActivitySlice) {
      state.singleActivitySlice = singleActivitySlice;
    },
    SET_SINGLE_DRAFT_ACTIVITY_SLICE(state, singleDraftActivitySlice) {
      state.singleDraftActivitySlice = singleDraftActivitySlice;
    },
    SET_DRAFT_ACTIVITY_SLICE_CASHFLOW(state, cashflow) {
      state.singleDraftActivitySliceCashflow = cashflow;
    },
    CLEAR_SINGLE_ACTIVITY_SLICE(state) {
      state.singleActivitySlice = {};
    },
    CLEAR_ACTIVITY_SLICES(state) {
      state.activitySlices = [];
    },
    CLEAR_DRAFT_ACTIVITY_SLICES(state) {
      state.draftActivitySlices = [];
    },
    CLEAR_SINGLE_DRAFT_ACTIVITY_SLICE(state) {
      state.singleDraftActivitySlice = {};
    },
    SET_CAMPAIGN_PRODUCTS_LIST(state, campaignProductsList) {
      state.campaignProductsList = campaignProductsList;
    },
    SET_CAMPAIGN_MODIFIERS(state, mods) {
      state.campaignModifiers = mods;
    },
    SET_FILTERED_CAMPAIGN_MODIFIERS(state, mods) {
      state.filteredCampaignModifiers = mods;
    },
    SET_YIELD_MODIFIERS(state, mods) {
      state.yieldModifiers = mods;
    },
    SET_PRICE_MODIFIERS(state, mods) {
      state.priceModifiers = mods;
    },
    SET_COST_ITEM_FAMILY_MODIFIERS(state, mods) {
      state.costItemFamilyModifiers = mods;
    },
    SET_FREIGHT_MODIFIERS(state, mods) {
      state.freightModifiers = mods;
    },
    SET_RENT_MODIFIERS(state, mods) {
      state.rentModifiers = mods;
    },
    SET_SLICE_PRODUCTS_LIST(state, sliceProductsList) {
      state.sliceProductsList = sliceProductsList;
    },
    SET_SLICE_COST_INPUT_PERIODS(state, sliceInputCostPeriods) {
      state.sliceInputCostPeriods = sliceInputCostPeriods;
    },
    SET_ESTABLISHMENTS(state, establishments) {
      state.establishments = establishments;
    },
    SET_SINGLE_ESTABLISHMENT(state, singleEstablishment) {
      state.singleEstablishment = singleEstablishment;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    SET_ACTIVITIES(state, activities) {
      state.activities = activities;
    },
    SET_SPECIES(state, species) {
      state.species = species;
    },
    SET_SINGLE_ABSTRACT_CAMPAIGN(state, singleAbstractCampaign) {
      state.singleAbstractCampaign = singleAbstractCampaign;
    },
    SET_ABSTRACT_CAMPAIGNS(state, abstractCampaigns) {
      state.abstractCampaigns = abstractCampaigns;
    },
    SET_CAMPAIGNS(state, campaigns) {
      state.campaigns = campaigns;
    },
    SET_SINGLE_CAMPAIGN(state, singleCampaign) {
      state.singleCampaign = singleCampaign;
    },
    SET_SINGLE_ENGAGEMENT(state, singleEngagement) {
      state.singleEngagement = singleEngagement;
    },
    SET_SINGLE_DRAFT_ENGAGEMENT(state, singleDraftEngagement) {
      state.singleDraftEngagement = singleDraftEngagement;
    },
    SET_DRAFT_ENGAGEMENT_METRICS(state, metrics) {
      state.singleDraftEngagementMetrics = metrics;
    },
    SET_ENGAGEMENT_METRICS(state, metrics) {
      state.singleEngagementMetrics = metrics;
    },
    SET_DIRECT_SLICE_COST_ITEMS(state, items) {
      state.directSliceCostItems = items;
    },
    SET_COST_ITEM_FAMILIES(state, families) {
      state.costItemFamilies = families;
    },
    SET_COST_ITEM_GROUPS(state, groups) {
      state.costItemGroups = groups;
    },
    SET_SINGLE_COST_GROUP_ELEMENTS(state, elements) {
      state.costItemGroupElements = elements;
    },
    SET_FINANCIAL_POLICIES(state, policies) {
      state.financialPolicies = policies;
    },
    SET_INTEREST_RATES(state, rates) {
      state.interestRates = rates;
    },
    SET_OTHER_TON_COST_ITEMS(state, items) {
      state.otherTonCostItems = items;
    },
    SET_PAGE_LOADING(state, status) {
      state.isPageLoading = status;
    },
    SET_ACTIVITY_SLICES_ECONOMIC_CASHFLOWS(state, cashflows) {
      state.activitySlicesEconomicCashflow = cashflows;
    },
    SET_ACTIVITY_SLICE_CASHFLOW(state, cashflow) {
      state.singleActivitySliceCashflow = cashflow;
    },
    SET_ENGAGEMENT_CASHFLOW(state, cashflow) {
      state.engagementCashflow = cashflow;
    },
    SET_CAMPAIGN_CASHFLOW(state, cashflow) {
      state.campaignCashflow = cashflow;
    },
    SET_CAMPAIGN_METRICS(state, metrics) {
      state.campaignMetrics = metrics;
    },
    SET_CAMPAIGN_SIMULATION_METRICS(state, metrics) {
      state.campaignSimulationMetrics = metrics;
    },
    SET_DRAFT_ENGAGEMENT_CASHFLOW(state, cashflow) {
      state.draftEngagementCashflow = cashflow;
    },
    SET_ENGAGEMENT_RESOURCE_UTILIZATION(state, resourceUtilization) {
      state.engagementResourceUtilization = resourceUtilization;
    },
    SET_DRAFT_ENGAGEMENT_RESOURCE_UTILIZATION(state, resourceUtilization) {
      state.draftEngagementResourceUtilization = resourceUtilization;
    },
    SET_CAMPAIGN_RESOURCE_UTILIZATION(state, resourceUtilization) {
      state.campaignResourceUtilization = resourceUtilization;
    },
    SET_DRAFT_ENGAGEMENTS_TO_COMPARE(state, draftEngagements) {
      state.draftEngagementsToCompare = draftEngagements;
    },
    SET_CAMPAIGN_VERSIONS_TO_COMPARE(state, campaignVersions) {
      state.campaignVersionsToCompare = campaignVersions;
    },
    SET_IS_DRAFT_ENGAGEMENT_COMPARE_ACTIVE(
      state,
      isDraftEngagementCompareActive
    ) {
      state.isDraftEngagementCompareActive = isDraftEngagementCompareActive;
    },
    CAMPAIGN_(state, t1MetricsComparison) {
      state.t1MetricsComparison = t1MetricsComparison;
    },
    SET_T4_METRICS_COMPARISON(state, t4MetricsComparison) {
      state.t4MetricsComparison = t4MetricsComparison;
    },
    SET_T3_METRICS_COMPARISON(state, t3MetricsComparison) {
      state.t3MetricsComparison = t3MetricsComparison;
    },
    SET_T1_METRICS_CAMPAIGN_COMPARISON(state, t1MetricsCampaignComparison) {
      state.t1MetricsCampaignComparison = t1MetricsCampaignComparison;
    },
    SET_T4_METRICS_CAMPAIGN_COMPARISON(state, t4MetricsCampaignComparison) {
      state.t4MetricsCampaignComparison = t4MetricsCampaignComparison;
    },
    SET_T3_METRICS_CAMPAIGN_COMPARISON(state, t3MetricsCampaignComparison) {
      state.t3MetricsCampaignComparison = t3MetricsCampaignComparison;
    },
    SET_CARTESIAN_PRODUCT_TERMS(state, cartesianProductTerms) {
      state.cartesianProductTerms = cartesianProductTerms;
    },
    SET_HOMEPAGE_METRICS(state, metrics) {
      state.homepageMetrics = metrics;
    },
  },
  actions: {
    setPageLoadingStatus({ commit }, status) {
      if (status === false) {
        commit("UPDATE_LOADING_STATUS", -1);
      } else if (status === true) {
        commit("UPDATE_LOADING_STATUS", +1);
      }
    },
    getAllUsers({ dispatch }) {
      dispatch("getAllManagers");
      dispatch("getAllDirectors");
    },
    async getAllDirectors({ commit, dispatch }) {
      let endpoint = "/user/director";
      let directorsResult = await getRequest(endpoint);
      if (directorsResult?.success) {
        commit("SET_ALL_DIRECTORS", directorsResult?.data);
      } else {
        dispatch("setNewNotification", directorsResult);
      }
    },
    async getAllManagers({ commit, dispatch }) {
      let endpoint = "/user/manager";
      let managersResult = await getRequest(endpoint);
      if (managersResult?.success) {
        let managers = managersResult?.data;
        let establishmentEndpoint = "/manager_to_establishment";
        let establishmentListResult = [];

        if (managers?.length > 0) {
          for (const manager of managers) {
            let filter = `?q=manager_id:${manager.id}`;
            establishmentListResult.push(
              getRequest(establishmentEndpoint + filter)
            );
          }
          establishmentListResult = await Promise.all(establishmentListResult);
          let i = 0;
          for (const manager of managers) {
            manager.establishments = [];
            if (establishmentListResult?.[i]?.success) {
              let establishmentsRelations = establishmentListResult?.[i]?.data;
              manager.establishmentsIds = establishmentsRelations.map(
                (el) => el.establishmentId
              );
              manager.establishmentsRelations = establishmentsRelations;
            } else {
              dispatch("setNewNotification", establishmentListResult?.[i]);
            }
            i++;
          }
        }
        commit("SET_ALL_MANAGERS", managers);
      } else {
        dispatch("setNewNotification", managersResult);
      }
    },
    unsetUserTokenInfo({ commit }) {
      commit("SET_USER_IS_LOGG", false);
      commit("SET_USER_TOKEN_INFO", {});
    },
    setUserTokenInfo({ commit }, decodedToken) {
      commit("SET_USER_IS_LOGG", true);
      commit("SET_USER_TOKEN_INFO", decodedToken);
    },
    async getActivityPricesFromCampaign({ commit, dispatch }, campaign_id) {
      let activityPrices = [];
      let endpoint = "/views/campaign_prices_by_activity";
      let filter = `?q=campaign_id:${campaign_id}`;
      let query = endpoint + filter;
      let response = await getRequest(query);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        activityPrices = kRowsToItems(
          response?.data?.rows,
          response?.data?.keys
        );
      }
      commit("SET_ACTIVITY_PRICES_FROM_CAMPAIGN", activityPrices);
    },
    setNotificationTimeOut({ dispatch }, payload) {
      let id = payload?.id ? payload.id : Date.now();
      // This will set a default timeout if no timeout is given
      let timeout = payload?.timeout ? payload.timeout : 5000;

      // Set notification timeout
      setTimeout(() => {
        dispatch("unSetNotification", id);
      }, timeout);
    },
    unSetNotification({ commit }, id) {
      commit("DELETE_NOTIFICATION_FROM_QUEUE", id);
    },
    setNewNotification({ commit, dispatch }, notification) {
      let notificationId = Date.now();
      let notif;
      let isNotificationParsed = checkNotificationStructure(notification);
      if (isNotificationParsed) {
        notif = notification.data;
        notif.id = notificationId;
        commit("ADD_NOTIFICATION_TO_QUEUE", notif);
      } else {
        console.log("bad notif", notification);
        notif = {
          id: notificationId,
          type: "alert",
          text: "Hubo un error con esta notificacion, no se pudo mostrar correctamente",
        };
        commit("ADD_NOTIFICATION_TO_QUEUE", notif);
      }
      let textCharacters = notif?.text.length;
      let infoCharacters = notif?.info?.reduce((acc, str) => {
        return acc + str.length;
      }, 0);
      // This will give a timeout of 1 sec every character
      let notificationTimeout = (textCharacters + infoCharacters) * 1000;
      dispatch("setNotificationTimeOut", {
        id: notificationId,
        timeout: notificationTimeout,
      });
    },
    setDraftEngagementCompareActive({ commit }) {
      commit("SET_IS_DRAFT_ENGAGEMENT_COMPARE_ACTIVE", true);
    },
    unSetDraftEngagementCompareActive({ commit }) {
      commit("SET_IS_DRAFT_ENGAGEMENT_COMPARE_ACTIVE", false);
    },
    setDraftEngagementsToCompare({ commit }, draftEngagements) {
      commit("SET_DRAFT_ENGAGEMENTS_TO_COMPARE", draftEngagements);
    },
    setCampaignVersionsToCompare({ commit }, campaignVersions) {
      commit("SET_CAMPAIGN_VERSIONS_TO_COMPARE", campaignVersions);
    },
    toggleSideBarDrawer({ commit }) {
      commit("TOGGLE_SIDEBAR_DRAWER");
    },

    async getMetricsForEngagementComparison(
      { commit, dispatch },
      draftEngagementIdArray
    ) {
      dispatch("setPageLoadingStatus", true);
      let endpoint = `/views/draft_activity_slice_summary?limit=10000`;
      let draftEngagementSummaryResponses = [];
      let draftEngagementsResponse = [];
      let t3MetricsComparison = {};
      for (const draftEngagementId of draftEngagementIdArray) {
        let summaryEndpoint =
          endpoint + `&q=draft_engagement_id:${draftEngagementId}`;
        draftEngagementSummaryResponses.push(getRequest(summaryEndpoint, true));
        draftEngagementsResponse.push(
          getRequest(`/draft_engagement/${draftEngagementId}`)
        );
      }

      let draftEngagementSummaryArray = await Promise.all(
        draftEngagementSummaryResponses
      );
      let draftEngagementsResult = await Promise.all(draftEngagementsResponse);

      // Initial values for years
      let startYear = 2049;
      let startMonth = 1;
      let endYear = 2001;
      let endMonth = 1;

      let i = 0;
      for (let j = 0; j < draftEngagementIdArray.length; j++) {
        if (!draftEngagementsResult?.[j].success) {
          dispatch("setNewNotification", draftEngagementsResult?.[j]);
        } else {
          let data = draftEngagementsResult?.[j]?.data;
          let date = new Date(data?.variableParticipationDate);
          date.setMonth(date.getMonth() - 18);
          if (startYear > date.getFullYear()) {
            startYear = date.getFullYear();
            startMonth = date.getMonth() + 1;
          }
          // Gives 18 months back
          date.setMonth(date.getMonth() + 18);
          // Add 1 Year more
          if (endYear < date.getFullYear() + 1) {
            endYear = date.getFullYear();
            endMonth = date.getMonth() + 1;
          }
        }
        if (!draftEngagementSummaryArray?.[j].success) {
          dispatch("setNewNotification", draftEngagementSummaryArray?.[j]);
        } else {
          let data = draftEngagementSummaryArray?.[j]?.data;
          let keys = data.keys;
          let items = kRowsToItems(data.rows, keys);
          items = items.map((item) => {
            return {
              ...item,
              totalRevenue: item?.totalRevenue?.toLocaleString("en-US", {
                timeZone: "America/Argentina/Buenos_Aires",
              }),
            };
          });
          t3MetricsComparison[draftEngagementIdArray[i]] = items;
          i++;
        }
      }

      endpoint = `/module/get/metrics/draft-engagement/compare/${draftEngagementIdArray[0]}/${draftEngagementIdArray[1]}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;
      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let t1MetricsComparison = response?.data?.t1;
        let t4MetricsComparison = response?.data?.t4;

        commit("CAMPAIGN_", t1MetricsComparison);
        commit("SET_T4_METRICS_COMPARISON", t4MetricsComparison);
        commit("SET_T3_METRICS_COMPARISON", t3MetricsComparison);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getMetricsForCampaignVersionComparison({ commit, dispatch }, ids) {
      dispatch("setPageLoadingStatus", true);
      let startYear;
      let startMonth;
      let endYear;
      let endMonth;
      let campaignVersionsIdArray = ids.campaignVersionsIdArray;
      let abstractCampaignId = ids.abstractCampaignId;
      let endpoint = `/views/campaign_activity_slice_summary?limit=10000`;
      let campaignVersionSummaryResponses = [];

      let t3MetricsComparison = {};
      let abstractCampaignResult = await getRequest(
        `/abstract_campaign/${abstractCampaignId}`
      );
      if (!abstractCampaignResult.success) {
        dispatch("setNewNotification", abstractCampaignResult);
      } else {
        let data = abstractCampaignResult?.data;
        let startDate = new Date(data?.startDate);
        let endDate = new Date(data?.endDate);
        startYear = startDate.getFullYear();
        startMonth = startDate.getMonth() + 1;
        endYear = endDate.getFullYear();
        endMonth = endDate.getMonth() + 1;
      }

      for (const campaignVersionId of campaignVersionsIdArray) {
        let summaryEndpoint = endpoint + `&q=campaign_id:${campaignVersionId}`;
        campaignVersionSummaryResponses.push(getRequest(summaryEndpoint, true));
      }

      let campaignVersionSummaryArray = await Promise.all(
        campaignVersionSummaryResponses
      );

      // Initial values for years

      let i = 0;
      for (let j = 0; j < campaignVersionsIdArray.length; j++) {
        if (!campaignVersionSummaryArray?.[j].success) {
          dispatch("setNewNotification", campaignVersionSummaryArray?.[j]);
        } else {
          let data = campaignVersionSummaryArray?.[j]?.data;

          let keys = data.keys;
          let items = kRowsToItems(data.rows, keys);
          items = items.map((item) => {
            return {
              ...item,
              totalRevenue: parseToLocaleString(item?.totalRevenue),
            };
          });
          t3MetricsComparison[campaignVersionsIdArray[i]] = items;
          i++;
        }
      }

      endpoint = `/module/get/metrics/campaign/compare/${campaignVersionsIdArray[0]}/${campaignVersionsIdArray[1]}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;
      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let t1MetricsComparison = response?.data?.t1;
        let t4MetricsComparison = response?.data?.t4;
        console.log(t1MetricsComparison);

        commit("SET_T1_METRICS_CAMPAIGN_COMPARISON", t1MetricsComparison);
        commit("SET_T4_METRICS_CAMPAIGN_COMPARISON", t4MetricsComparison);
        commit("SET_T3_METRICS_CAMPAIGN_COMPARISON", t3MetricsComparison);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getHomepageMetrics({ commit, dispatch }) {
      let endpoint = `/module/get/metrics/home`;
      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
        return;
      }

      let homepageMetrics = response.data;

      commit("SET_HOMEPAGE_METRICS", homepageMetrics);
    },
    async getSingleEngagementSummary({ commit, dispatch }, engagementId) {
      let endpoint = `/views/enriched_campaign_engagements?q=engagement_id:${engagementId}`;
      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let summaries = kRowsToItems(response.data.rows, response.data.keys);

        if (summaries.length !== 1) {
          let notification = {
            data: {
              type: "alert",
              text: `${endpoint} failed! Length != 0. ${JSON.stringify(
                summaries
              )}`,
            },
          };
          dispatch("setNewNotification", notification);
        }
        let summary = summaries[0];
        commit("SET_ENGAGEMENT_SUMMARY", summary);
      }
    },
    clearSingleDraftEngagementMetrics({ commit }) {
      commit("SET_DRAFT_ENGAGEMENT_METRICS", {});
    },
    async getSingleDraftEngagementMetrics({ commit, dispatch }, payload) {
      let id = payload.id;
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let endpoint = `/module/get/metrics/draft-engagement/${id}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;
      let metrics = {};
      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        metrics = response?.data;
        commit("SET_DRAFT_ENGAGEMENT_METRICS", metrics);
      }
    },
    async getSingleEngagementMetrics({ commit, dispatch }, payload) {
      let id = payload.id;
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let endpoint = `/module/get/metrics/engagement/${id}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;
      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let metrics = response.data;
        commit("SET_ENGAGEMENT_METRICS", metrics);
      }
    },
    async getAvailableViews({ commit, dispatch, state }) {
      state.availableViewsLoading = true;
      let response = await getRequest("/views", true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let views = response.data.views;
        commit("SET_VIEWS", views);
      }
      state.availableViewsLoading = false;
    },
    async getDbView({ commit, dispatch, state }, payload) {
      state.viewObject = {};
      let offset = (payload.page - 1) * 100;
      let view = payload.view;
      let limit = payload.limit;
      state.viewObjectLoading = true;
      let response = await getRequest(
        "/views/" + view + "?limit=" + limit + "&offset=" + offset,
        true
      );
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let viewObject = response.data;

        viewObject = processViewObject(viewObject);

        commit("SET_VIEW_OBJECT", viewObject);
        state.viewObjectLoading = false;
      }
    },
    async getSummaries({ commit, dispatch, state }) {
      state.summariesLoading = true;
      let r1 = await getRequest("/views/enriched_summary_by_activity", true);
      let r2 = await getRequest("/views/enriched_summary_by_region", true);
      let r3 = await getRequest("/views/ra_simulation", true);
      let r4 = await getRequest("/activity", false);
      let responsesHasError = false;
      for (const response of [r1, r2, r3, r4]) {
        if (!response.success) {
          dispatch("setNewNotification", response);
          responsesHasError = true;
        }
      }
      if (!responsesHasError) {
        let byRegion = r2.data;
        let byActivity = r1.data;
        let simFactors = r3.data;
        let forwardPrices = r4.data;
        let fpricekeys = Object.keys(forwardPrices[0]);

        let forwardPricesObject = {
          rows: forwardPrices.map((K) => {
            let res = [];
            for (let i = 0; i < fpricekeys.length; i++) {
              const element = K[fpricekeys[i]];
              res.push(element);
            }
            return res;
          }),
          keys: fpricekeys,
        };

        commit("SET_SUMMARY_BY_ACTIVITY", processViewObject(byActivity));
        commit("SET_SUMMARY_BY_REGION", processViewObject(byRegion));
        commit("SET_SIM_FACTORS", simFactors);
        commit("SET_FORWARD_PRICES", forwardPricesObject);
      }
      state.summariesLoading = false;
    },
    async updateFactor({ dispatch, state }, payload) {
      state.summariesLoading = true;

      let id = payload.id;
      let factor = payload.factor;
      let response = await putRequest("/region_activity_simulation/" + id, {
        factor,
      });

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatch("getSummaries");
      }
    },
    async updateForwardPrice({ dispatch, state }, payload) {
      state.summariesLoading = true;
      let id = payload.id;
      let forwardPrice = payload.forwardPrice;

      let response = await putRequest("/activity/" + id, {
        forwardPrice,
      });

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatch("getSummaries");
      }
    },
    async getSingleDraftActivitySlice(
      { commit, dispatch },
      draftActivitySliceId
    ) {
      dispatch("setPageLoadingStatus", true);
      try {
        let draftActivitySlice = await getDraftASByID(draftActivitySliceId);

        if (!draftActivitySlice.success) {
          if (draftActivitySlice.isArray) {
            let arrayResponse = draftActivitySlice.data;
            for (const response of arrayResponse) {
              dispatch("setNewNotification", response);
            }
          } else {
            dispatch("setNewNotification", draftActivitySlice);
          }
        } else {
          draftActivitySlice = draftActivitySlice.data;
          commit("SET_SINGLE_DRAFT_ACTIVITY_SLICE", draftActivitySlice);
        }
      } catch (error) {
        let notif = { type: "error", text: `${error}` };
        dispatch("setNewNotification", notif);
        console.log(`Catching exception:`, error);
      }

      dispatch("setPageLoadingStatus", false);
    },
    async sortDraftActivitySliceCostItems({ commit, state }, sortKey) {
      let singleDraftActivitySlice = { ...state.singleDraftActivitySlice };
      singleDraftActivitySlice.assignedSCostItems =
        singleDraftActivitySlice.assignedSCostItems.sort(
          (a, b) => a[sortKey] - b[sortKey]
        );
      commit("SET_SINGLE_DRAFT_ACTIVITY_SLICE", singleDraftActivitySlice);
    },
    async getSingleActivitySlice({ commit, dispatch }, activitySliceId) {
      dispatch("setPageLoadingStatus", true);

      try {
        let activitySlice = await getASByID(activitySliceId);

        if (!activitySlice.success) {
          if (activitySlice.isArray) {
            let arrayResponse = activitySlice.data;
            for (const response of arrayResponse) {
              dispatch("setNewNotification", response);
            }
          } else {
            dispatch("setNewNotification", activitySlice);
          }
        } else {
          activitySlice = activitySlice.data;
          commit("SET_SINGLE_ACTIVITY_SLICE", activitySlice);
        }
      } catch (e) {
        let notif = { type: "error", text: `${e}` };
        dispatch("setNewNotification", notif);
        console.log(`Catching exception:`, e);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async clearSingleActivitySlice({ commit }) {
      commit("CLEAR_SINGLE_ACTIVITY_SLICE");
    },
    async clearActivitySlices({ commit }) {
      commit("CLEAR_ACTIVITY_SLICES");
    },
    async clearDraftActivitySlices({ commit }) {
      commit("CLEAR_DRAFT_ACTIVITY_SLICES");
    },
    async clearSingleDraftActivitySlice({ commit }) {
      commit("CLEAR_SINGLE_DRAFT_ACTIVITY_SLICE");
    },
    async clearDraftEngagements({ commit }) {
      commit("CLEAR_DRAFT_ENGAGEMENT");
    },
    async clearEngagements({ commit }) {
      commit("CLEAR_ENGAGEMENTS");
    },
    async clearEngagement({ commit }) {
      commit("CLEAR_ENGAGEMENT");
    },

    async getDraftActivitySliceCashflow({ commit, dispatch }, payload) {
      let masterCashflow = { rows: [], keys: [] };
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let activitySliceId = payload?.activitySliceId;

      let endpoint = `/module/get/draft-cashflow/activity_slice/${activitySliceId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;
      let result = await getRequest(endpoint, true);
      if (!result.success) {
        dispatch("setNewNotification", result);
      } else {
        masterCashflow = result.data;
        masterCashflow.rows = formatRowsToLocaleString(masterCashflow.rows);
        commit("SET_DRAFT_ACTIVITY_SLICE_CASHFLOW", masterCashflow);
      }
    },
    async getDraftEngagementCashflow({ commit, dispatch }, payload) {
      let masterCashflow = { rows: [], keys: [] };
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let draftEngagementId = payload?.draftEngagementId;
      let endpoint = `/module/get/draft-cashflow/engagement/${draftEngagementId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;
      let result = await getRequest(endpoint, true);
      if (!result.success) {
        dispatch("setNewNotification", result);
      } else {
        masterCashflow = result.data;
        masterCashflow.rows = formatRowsToLocaleString(masterCashflow.rows);
        commit("SET_DRAFT_ENGAGEMENT_CASHFLOW", masterCashflow);
      }
    },
    async getActivitySliceCashflow({ commit, dispatch }, payload) {
      let masterCashflow = { rows: [], keys: [] };
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let activitySliceId = payload?.activitySliceId;

      let endpoint = `/module/get/project-cashflow/activity_slice/${activitySliceId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;
      let result = await getRequest(endpoint, true);
      if (!result.success) {
        dispatch("setNewNotification", result);
      } else {
        masterCashflow = result.data;
        masterCashflow.rows = formatRowsToLocaleString(masterCashflow?.rows);
        commit("SET_ACTIVITY_SLICE_CASHFLOW", masterCashflow);
      }
    },
    async getEngagementCashflow({ commit, dispatch }, payload) {
      let masterCashflow = { rows: [], keys: [] };
      let masterRU = { rows: [], keys: [] };
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let engagementId = payload?.engagementId;
      let endpoint = `/module/get/project-cashflow/engagement/${engagementId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;

      // Get both cashflow and resource utilization in parallel
      let [cashflowResult, ruResult] = await Promise.all([
        getRequest(endpoint, true),
        retrieveMultiResourceUtilization(
          payload.activitySliceIds // We'll need to pass this from the component
        ),
      ]);

      if (!cashflowResult.success) {
        dispatch("setNewNotification", cashflowResult);
      } else {
        masterCashflow = cashflowResult.data;
        masterCashflow.rows = formatRowsToLocaleString(masterCashflow.rows);
        commit("SET_ENGAGEMENT_CASHFLOW", masterCashflow);
      }

      if (!ruResult.success) {
        dispatch("setNewNotification", ruResult);
      } else {
        masterRU = ruResult.data;
        commit("SET_ENGAGEMENT_RESOURCE_UTILIZATION", masterRU);
      }
    },
    async getCampaignCashflow({ commit, dispatch }, payload) {
      let masterCashflow = { rows: [], keys: [] };
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let campaignId = payload?.campaignId;
      let endpoint = `/module/get/project-cashflow/campaign/${campaignId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;

      if (payload?.speciesId) {
        endpoint += `&species_id=${payload?.speciesId}`;
      }
      if (payload?.regionId) {
        endpoint += `&region_id=${payload?.regionId}`;
      }

      let result = await getRequest(endpoint, true);
      if (!result.success) {
        dispatch("setNewNotification", result);
      } else {
        masterCashflow = result.data;
        masterCashflow.rows = formatRowsToLocaleString(masterCashflow.rows);
        commit("SET_CAMPAIGN_CASHFLOW", masterCashflow);
      }
    },
    async getCampaignMetrics({ commit, dispatch }, payload) {
      let metrics = {};
      let startYear = payload?.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload?.endYear;
      let endMonth = payload.endMonth;

      let campaignId = payload?.campaignId;
      let endpoint = `/module/get/metrics/campaign/${campaignId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1`;

      if (payload?.speciesId) {
        endpoint += `&species_id=${payload?.speciesId}`;
      }
      if (payload?.regionId) {
        endpoint += `&region_id=${payload?.regionId}`;
      }

      let result = await getRequest(endpoint, true);
      if (!result.success) {
        dispatch("setNewNotification", result);
      } else {
        metrics = result.data;
        commit("SET_CAMPAIGN_METRICS", metrics);
      }
    },

    async clearCampaignMetricsAndCashflow({ commit }) {
      commit("SET_CAMPAIGN_CASHFLOW", {});
      commit("SET_CAMPAIGN_METRICS", {});
    },

    async clearEngagemetMetricsAndCashflow({ commit }) {
      commit("SET_CAMPAIGN_METRICS", {});
      commit("SET_ENGAGEMENT_METRICS", {});
    },

    async getCampaignSimulationCashflow({ commit, dispatch }, payload) {
      let masterCashflow = { rows: [], keys: [] };
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let campaignId = payload?.campaignId;
      let endpoint = `/module/get/project-cashflow/campaign/${campaignId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1&use_modifiers=1`;
      let result = await getRequest(endpoint, true);
      if (!result.success) {
        dispatch("setNewNotification", result);
      } else {
        masterCashflow = result.data;
        masterCashflow.rows = formatRowsToLocaleString(masterCashflow.rows);
        commit("SET_CAMPAIGN_CASHFLOW", masterCashflow);
      }
    },
    async getCampaignSimulationMetrics({ commit, dispatch }, payload) {
      let metrics = {};
      let startYear = payload.startYear;
      let startMonth = payload.startMonth;
      let endYear = payload.endYear;
      let endMonth = payload.endMonth;

      let campaignId = payload?.campaignId;
      let endpoint = `/module/get/metrics/campaign/${campaignId}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}&use_gather=1&use_modifiers=1`;
      let result = await getRequest(endpoint, true);
      if (!result.success) {
        dispatch("setNewNotification", result);
      } else {
        metrics = result.data;
        commit("SET_CAMPAIGN_SIMULATION_METRICS", metrics);
      }
    },

    async getYieldModifiersByCampaignId({ dispatch, commit }, campaignId) {
      let endpoint = `/simulation_modifier_yield?q=campaign_id:${campaignId}&limit=10000`;
      let result = await getRequest(endpoint);
      if (!result?.success) {
        dispatch("setNewNotification", result);
      } else {
        commit("SET_YIELD_MODIFIERS", result.data);
      }
    },
    async getPriceModifiersByCampaignId({ dispatch, commit }, campaignId) {
      let endpoint = `/simulation_modifier_price?q=campaign_id:${campaignId}&limit=10000`;
      let result = await getRequest(endpoint);
      if (!result?.success) {
        dispatch("setNewNotification", result);
      } else {
        commit("SET_PRICE_MODIFIERS", result.data);
      }
    },
    async getCostItemFamilyModifiersByCampaignId(
      { dispatch, commit },
      campaignId
    ) {
      let endpoint = `/simulation_modifier_cost_item_family?q=campaign_id:${campaignId}&limit=10000`;
      let result = await getRequest(endpoint);
      if (!result?.success) {
        dispatch("setNewNotification", result);
      } else {
        commit("SET_COST_ITEM_FAMILY_MODIFIERS", result.data);
      }
    },
    async getFreightModifiersByCampaignId({ dispatch, commit }, campaignId) {
      let endpoint = `/simulation_modifier_freight?q=campaign_id:${campaignId}&limit=10000`;
      let result = await getRequest(endpoint);
      if (!result?.success) {
        dispatch("setNewNotification", result);
      } else {
        commit("SET_FREIGHT_MODIFIERS", result.data);
      }
    },
    async getRentModifiersByCampaignId({ dispatch, commit }, campaignId) {
      let endpoint = `/simulation_modifier_rent?q=campaign_id:${campaignId}&limit=10000`;
      let result = await getRequest(endpoint);
      if (!result?.success) {
        dispatch("setNewNotification", result);
      } else {
        commit("SET_RENT_MODIFIERS", result.data);
      }
    },
    async getCampaignActivities({ commit, dispatch }, payload) {
      dispatch("setPageLoadingStatus", true);

      let filterKey = payload?.filterKey;
      let filterValue = payload?.filterValue;
      let endpoint1 = `/views/campaign_activity_slice_summary?limit=10000`;
      let endpoint2 = `/views/activity_slice_revenues?limit=10000`;

      if (filterKey && filterValue) {
        endpoint1 = `/views/campaign_activity_slice_summary?limit=10000&q=${filterKey}:${filterValue}`;
        endpoint2 = `/views/activity_slice_revenues?limit=10000&q=${filterKey}:${filterValue}`;
      }

      let [response1, response2] = await Promise.all([
        getRequest(endpoint1, true),
        getRequest(endpoint2, true),
      ]);

      if (!response1.success) {
        dispatch("setNewNotification", response1);
      } else if (!response2.success) {
        dispatch("setNewNotification", response2);
      } else {
        let data1 = response1.data;
        let data2 = response2.data;
        let keys1 = data1.keys;
        let keys2 = data2.keys;
        let rows1 = formatRowsToLocaleString(data1.rows);
        let rows2 = formatRowsToLocaleString(data2.rows);
        let items1 = kRowsToItems(rows1, keys1);
        let items2 = kRowsToItems(rows2, keys2);

        commit("SET_CAMPAIGN_ACTIVITIES", items1);
        commit("SET_CAMPAIGN_ACTIVITY_SLICES", items2);
      }
      dispatch("setPageLoadingStatus", false);
    },

    async getCampaignResourceUtilization({ commit, dispatch }, { campaignId }) {
      let masterRU = { rows: [], keys: [] };
      try {
        masterRU = await retrieveCampaignMultiResourceUtilization(campaignId);

        if (!masterRU.success) {
          dispatch("setNewNotification", masterRU);
        } else {
          masterRU = masterRU.data;
          commit("SET_CAMPAIGN_RESOURCE_UTILIZATION", masterRU);
        }
      } catch {
        let notif = {
          type: "error",
          text: "Fallo al tratar de traer la utilización de recursos",
        };
        dispatch("setNewNotification", notif);
        console.log("GET masterRU failed!");
      }
    },

    async getEngagementActivitySlices(
      { commit, dispatch },
      { filterKey, filterValue } = {}
    ) {
      dispatch("setPageLoadingStatus", true);

      try {
        const baseEndpoint = "views";
        const queryParams =
          filterKey && filterValue ? `&q=${filterKey}:${filterValue}` : "";

        const endpoints = {
          sliceSummary: `/${baseEndpoint}/engagement_activity_slice_summary?limit=10000${queryParams}`,
          aggSummary: `/${baseEndpoint}/agg_engagement_activity_slice_summary?limit=10000${queryParams}`,
        };

        const [sliceResponse, aggResponse] = await Promise.all([
          getRequest(endpoints.sliceSummary, true),
          getRequest(endpoints.aggSummary, true),
        ]);

        // Check if either request failed
        if (!sliceResponse.success || !aggResponse.success) {
          dispatch(
            "setNewNotification",
            !sliceResponse.success ? sliceResponse : aggResponse
          );
          return;
        }

        const processData = ({ data: { keys, rows } }) => {
          const formattedRows = formatRowsToLocaleString(rows);
          return kRowsToItems(formattedRows, keys);
        };

        commit("SET_ENGAGEMENT_ACTIVITY_SLICES", processData(sliceResponse));
        commit("SET_AGG_ENGAGEMENT_T3_METRICS", processData(aggResponse));
      } catch (error) {
        dispatch("setNewNotification", {
          success: false,
          message: error.message,
        });
      } finally {
        dispatch("setPageLoadingStatus", false);
      }
    },
    async getActivitySlices({ commit, dispatch }, payload) {
      dispatch("setPageLoadingStatus", true);

      let filterKey = payload?.filterKey;
      let filterValue = payload?.filterValue;
      let endpoint = `/views/activity_slice_summary?limit=10000`;

      if (filterKey && filterValue) {
        endpoint = `/views/activity_slice_summary?limit=10000&q=${filterKey}:${filterValue}`;
      }

      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let data = response.data;
        let keys = data.keys;
        let rows = formatRowsToLocaleString(data.rows);
        let items = kRowsToItems(rows, keys);
        if (items.length) {
          let masterRU = { rows: [], keys: [] };
          try {
            masterRU = await retrieveMultiResourceUtilization(
              items.map((i) => i.activitySliceId)
            );
            if (!masterRU.success) {
              dispatch("setNewNotification", masterRU);
            } else {
              masterRU = masterRU.data;
            }
          } catch {
            let notif = {
              type: "error",
              text: "Fallo al tratar de traer la utilización de recursos",
            };
            dispatch("setNewNotification", notif);
            console.log("GET masterRU failed!");
          }

          commit("SET_ACTIVITY_SLICES", items);
        }
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getDraftEngagementActivitySlices({ commit, dispatch }, payload) {
      dispatch("setPageLoadingStatus", true);
      let masterRU = { rows: [], keys: [] };
      let filterKey = payload?.filterKey;
      let filterValue = payload?.filterValue;
      let endpoint1 = `/views/draft_engagement_activity_slice_summary?limit=10000`;
      let endpoint2 = `/views/agg_draft_engagement_activity_slice_summary?limit=10000`;

      if (filterKey && filterValue) {
        endpoint1 = `/views/draft_engagement_activity_slice_summary?limit=10000&q=${filterKey}:${filterValue}`;
        endpoint2 = `/views/agg_draft_engagement_activity_slice_summary?limit=10000&q=${filterKey}:${filterValue}`;
      }

      let [response1, response2] = await Promise.all([
        getRequest(endpoint1, true),
        getRequest(endpoint2, true),
      ]);
      if (!response1.success) {
        dispatch("setNewNotification", response1);
      } else if (!response2.success) {
        dispatch("setNewNotification", response2);
      } else {
        let data1 = response1.data;
        let data2 = response2.data;
        let keys1 = data1.keys;
        let keys2 = data2.keys;
        let rows1 = formatRowsToLocaleString(data1.rows);
        let rows2 = formatRowsToLocaleString(data2.rows);
        let items1 = kRowsToItems(rows1, keys1);
        let items2 = kRowsToItems(rows2, keys2);
        if (items1.length) {
          try {
            masterRU = await retrieveDraftMultiResourceUtilization(
              items1.map((i) => i.draftActivitySliceId)
            );
            if (!masterRU.success) {
              dispatch("setNewNotification", masterRU);
            } else {
              masterRU = masterRU.data;
            }
          } catch {
            let notif = {
              type: "error",
              text: "Fallo al tratar de traer la utilización de recursos",
            };
            dispatch("setNewNotification", notif);
            console.log("GET masterRU failed!");
          }
        }
        commit("SET_DRAFT_ENGAGEMENT_ACTIVITY_SLICES", items1);
        commit("SET_AGG_DRAFT_ENGAGEMENT_T3_METRICS", items2);
        commit("SET_DRAFT_ENGAGEMENT_RESOURCE_UTILIZATION", masterRU);
      }

      dispatch("setPageLoadingStatus", false);
    },
    async getDraftActivitySlices({ commit, dispatch }, payload) {
      dispatch("setPageLoadingStatus", true);

      let filterKey = payload?.filterKey;
      let filterValue = payload?.filterValue;
      let endpoint = `/views/draft_activity_slice_summary?limit=10000`;

      if (filterKey && filterValue) {
        endpoint = `/views/draft_activity_slice_summary?limit=10000&q=${filterKey}:${filterValue}`;
      }

      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let data = response.data;
        let keys = data.keys;
        let rows = formatRowsToLocaleString(data.rows);
        let items = kRowsToItems(rows, keys);
        if (items.length) {
          commit("SET_DRAFT_ACTIVITY_SLICES", items);
        }
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getEstablishments({ commit, dispatch }) {
      dispatch("setPageLoadingStatus", true);

      let response = await getRequest("/establishment?limit=1000", false);

      let regionsRes = await getRequest("/region?limit=1000", false);
      if (!regionsRes.success || !response.success) {
        if (!response.success) {
          dispatch("setNewNotification", response);
        }
        if (!regionsRes.success) {
          dispatch("setNewNotification", regionsRes);
        }
      } else {
        let regions = regionsRes.data;

        let data = response.data;

        for (let i = 0; i < data.length; i++) {
          const establishment = data[i];

          let rId = establishment.regionId;
          let regionName = regions.find((i) => i.id === rId)?.name;

          establishment.regionName = regionName;
          data[i] = establishment;
        }

        commit("SET_ESTABLISHMENTS", data);
        commit("SET_REGIONS", regions);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getSingleEstablishment({ commit, dispatch }, establishmentId) {
      dispatch("setPageLoadingStatus", true);

      let response = await getRequest(
        "/establishment/" + establishmentId,
        false
      );

      let regionsRes = await getRequest("/region?limit=1000", false);

      if (!regionsRes.success || !response.success) {
        if (!response.success) {
          dispatch("setNewNotification", response);
        }
        if (!regionsRes.success) {
          dispatch("setNewNotification", regionsRes);
        }
      } else {
        let regions = regionsRes.data;

        let establishment = response.data;

        let rId = establishment.regionId;
        let regionName = regions.find((i) => i.id === rId)?.name;

        establishment.regionName = regionName;

        establishment.region = {
          id: establishment.regionId,
          name: establishment.regionName,
        };

        commit("SET_SINGLE_ESTABLISHMENT", establishment);
        commit("SET_REGIONS", regions);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getEngagements({ commit, dispatch }) {
      dispatch("setPageLoadingStatus", true);
      let engagementResponse = await getRequest("/engagement", false);
      if (engagementResponse.success) {
        commit("SET_ENGAGEMENTS", engagementResponse.data);
      } else {
        dispatch("setNewNotification", engagementResponse);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getSingleEngagement({ commit, dispatch }, id) {
      dispatch("setPageLoadingStatus", true);

      let engagementResponse = await getRequest("/engagement/" + id, false);
      if (!engagementResponse.success) {
        dispatch("setNewNotification", engagementResponse);
      } else {
        let engagement = {
          ...engagementResponse.data,
          rentPaymentPlan: [],
          rentFixedRows: [],
          rentBonuses: [],
        };
        let contract = CONTRACTS?.find(
          (el) => el.value == engagement.contractType
        );

        if (contract) {
          // Rents
          // This will make requests based on the contract type used by the engagement
          for (const endpointInfo of contract?.endpoints) {
            let finalEndpoint =
              endpointInfo?.endpoint + "?q=engagement_id:" + id;
            let rowsResult = await getRequest(finalEndpoint);

            if (!rowsResult?.success) {
              this.$store.dispatch("setNewNotification", rowsResult);
            } else {
              engagement[endpointInfo?.key] = rowsResult?.data.sort(
                (a, b) =>
                  new Date(a?.[endpointInfo?.orderBy]) -
                  new Date(b?.[endpointInfo?.orderBy])
              );
            }
          }
        }
        commit("SET_SINGLE_ENGAGEMENT", engagement);

        dispatch("getSingleCampaign", engagement.campaignId);
        dispatch("getSingleEstablishment", engagement.establishmentId);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getParentDraftEngagement({ commit, dispatch }, id) {
      dispatch("setPageLoadingStatus", true);
      let engagementResponse = await getRequest(
        "/draft_engagement/" + id,
        false
      );

      if (!engagementResponse.success) {
        // If 404, it means parent was deleted, else error
        if (engagementResponse?.data?.status != 404) {
          dispatch("setNewNotification", engagementResponse);
        }
      } else {
        let engagement = engagementResponse.data;

        commit("SET_SINGLE_DRAFT_ENGAGEMENT", engagement);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getCompleteSingleDraftEngagement({ commit, dispatch }, id) {
      dispatch("setPageLoadingStatus", true);
      let engagementResponse = await getRequest(
        "/draft_engagement/" + id,
        false
      );

      if (!engagementResponse.success) {
        dispatch("setNewNotification", engagementResponse);
      } else {
        let engagement = {
          ...engagementResponse.data,
          rentPaymentPlan: [],
          rentFixedRows: [],
          rentBonuses: [],
        };
        let contract = DRAFT_CONTRACTS?.find(
          (el) => el.value == engagement.contractType
        );
        if (contract) {
          // Rents
          // This will make requests based on the contract type used by the engagement
          for (const endpointInfo of contract?.endpoints) {
            let finalEndpoint =
              endpointInfo?.endpoint + "?q=draft_engagement_id:" + id;
            let rowsResult = await getRequest(finalEndpoint);

            if (!rowsResult?.success) {
              this.$store.dispatch("setNewNotification", rowsResult);
            } else {
              engagement[endpointInfo?.key] = rowsResult?.data.sort(
                (a, b) =>
                  new Date(a?.[endpointInfo?.orderBy]) -
                  new Date(b?.[endpointInfo?.orderBy])
              );
            }
          }
        }
        commit("SET_SINGLE_DRAFT_ENGAGEMENT", engagement);
        dispatch("getSingleEstablishment", engagement?.establishmentId);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async clearCampaignEngagements({ commit }) {
      commit("SET_CAMPAIGN_ENGAGEMENTS", []);
    },
    async getCampaignEngagements({ commit, dispatch }, campaignId) {
      let queryArg = campaignId ? `q=campaign_id:${campaignId}` : "";

      dispatch("setPageLoadingStatus", true);

      let response = await getRequest(
        "/views/enriched_campaign_engagements?limit=1000&" + queryArg,
        true
      );
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let data = response.data;
        let keys = [...data.keys];
        let items = kRowsToItems(data.rows, keys);

        data.items = items;
        delete data.rows;

        data.keys = data.keys.map((i) => {
          return { text: i, value: i };
        });

        commit("SET_CAMPAIGN_ENGAGEMENTS", data);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getDraftEngagementsByIdArray({ dispatch }, draftEngagementsIdArray) {
      let endpoint = "/views/enriched_draft_engagements?limit=1000";
      let dataOfArraysToCompare = [];

      for (const id of draftEngagementsIdArray) {
        let endpointFiltered = endpoint + `&q=draft_engagement_id:${id}`;
        let response = await getRequest(endpointFiltered, true);
        if (!response.success) {
          dispatch("setNewNotification", response);
        } else {
          let data = response.data;
          let keys = [...data.keys];
          let items = kRowsToItems(data.rows, keys);

          delete data.rows;

          dataOfArraysToCompare.push(...items);
        }
      }
      dispatch("setDraftEngagementsToCompare", dataOfArraysToCompare);
    },
    async getCampaignVersionByIdArray({ dispatch }, campaignVersionsIdArray) {
      let endpoint = "/campaign";
      let dataOfArraysToCompare = [];
      for (const id of campaignVersionsIdArray) {
        let finalEndpoint = endpoint + `/${id}`;
        let response = await getRequest(finalEndpoint, true);
        if (!response.success) {
          dispatch("setNewNotification", response);
        } else {
          let data = response.data;
          dataOfArraysToCompare.push(data);
        }
      }
      dispatch("setCampaignVersionsToCompare", dataOfArraysToCompare);
    },
    async getDraftEngagements({ commit, dispatch }, establishmentId) {
      dispatch("setPageLoadingStatus", true);
      let endpoint = "/views/enriched_draft_engagements?limit=1000";
      if (establishmentId) {
        endpoint = `/views/enriched_draft_engagements?limit=1000&q=establishment_id:${establishmentId}`;
      }

      let response = await getRequest(endpoint, true);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let data = response.data;
        let keys = [...data.keys];
        let items = kRowsToItems(data.rows, keys);

        data.items = items;
        delete data.rows;

        data.keys = data.keys.map((i) => {
          return { text: i, value: i };
        });

        commit("SET_DRAFT_ENGAGEMENTS", data);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getCostItemFamily({ commit, dispatch }) {
      dispatch("setPageLoadingStatus", true);
      let response = await getRequest("/cost_item_family?limit=1000", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let costItemFamilies = response.data;
        commit("SET_COST_ITEM_FAMILIES", costItemFamilies);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getAllCostItems({ commit, dispatch }) {
      dispatch("setPageLoadingStatus", true);

      let r1 = await getRequest("/direct_slice_cost_item?limit=5000", false);

      let r3 = await getRequest("/cost_item_family", false);

      if (!r1.success || !r3.success) {
        if (!r1.success) {
          dispatch("setNewNotification", r1);
        }
        if (!r3.success) {
          dispatch("setNewNotification", r3);
        }
      } else {
        let directSliceCostItems = r1.data;
        // Lookup the cost item family names
        let costItemFamilies = r3.data;

        for (let i = 0; i < directSliceCostItems.length; i++) {
          const item = directSliceCostItems[i];
          let family_id = item.costItemFamilyId;
          let family = costItemFamilies.find((i) => i.id === family_id);
          directSliceCostItems[i].costItemFamilyName = family.name;
        }

        commit("SET_DIRECT_SLICE_COST_ITEMS", directSliceCostItems);
        commit("SET_COST_ITEM_FAMILIES", costItemFamilies);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getSingleAbstractCampaign({ commit, dispatch }, id) {
      dispatch("setPageLoadingStatus", true);
      let response = await getRequest(`/abstract_campaign/${id}`, false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let abstractCampaign = response.data;
        commit("SET_SINGLE_ABSTRACT_CAMPAIGN", abstractCampaign);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getAbstractCampaigns({ commit, dispatch }) {
      dispatch("setPageLoadingStatus", true);
      let response = await getRequest("/abstract_campaign", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let abstractCampaigns = response.data;
        commit("SET_ABSTRACT_CAMPAIGNS", abstractCampaigns);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getCampaigns({ commit, dispatch }, abstractCampaignId) {
      let endpoint = "/campaign";
      if (abstractCampaignId) {
        endpoint =
          endpoint +
          "?q=" +
          `abstract_campaign_id:${abstractCampaignId}` +
          "&limit=1000";
      } else {
        endpoint = endpoint + "?limit=1000";
      }
      dispatch("setPageLoadingStatus", true);
      let response = await getRequest(endpoint, false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let campaigns = response.data;
        commit("SET_CAMPAIGNS", campaigns);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getSingleCampaign({ commit, dispatch }, campaignId) {
      dispatch("setPageLoadingStatus", true);
      let response = await getRequest("/campaign/" + campaignId, false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let campaign = response.data;
        commit("SET_SINGLE_CAMPAIGN", campaign);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getActivities({ commit, dispatch }) {
      let response = await getRequest("/activity?limit=1000", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let activities = response.data;
        commit("SET_ACTIVITIES", activities);
      }
    },
    async getSpecies({ commit, dispatch }) {
      dispatch("setPageLoadingStatus", true);
      let response = await getRequest("/species?limit=1000", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let species = response.data;
        commit("SET_SPECIES", species);
      }
      dispatch("setPageLoadingStatus", false);
    },
    unsetCampaignModifiers({ commit }) {
      commit("SET_CAMPAIGN_MODIFIERS", []);
    },
    unsetFilteredCampaignModifiers({ commit }) {
      commit("SET_FILTERED_CAMPAIGN_MODIFIERS", []);
    },
    async getCampaignModifiers({ commit, dispatch }, campaignId) {
      dispatch("setPageLoadingStatus", true);
      let q = `?q=campaign_id:${campaignId}&limit=5000`;
      let response = await getRequest(
        "/simulation_modifier_cost_item_family/" + q,
        false
      );
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let campaignMods = response.data;
        commit("SET_CAMPAIGN_MODIFIERS", campaignMods);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async getFilteredCampaignModifiers({ commit, dispatch }, filters) {
      dispatch("setPageLoadingStatus", true);
      let q = "";
      let campaignId = filters?.campaignId;
      let regionId = filters?.regionId;
      let speciesId = filters?.speciesId;
      let costItemFamilyId = filters?.costItemFamilyId;
      let modifier = filters?.modifier;

      let obj = {
        campaignId: campaignId ? `campaign_id:${campaignId}` : "",
        regionId: regionId ? `region_id:${regionId}` : "",
        speciesId: speciesId ? `species_id:${speciesId}` : "",
        costItemFamilyId: costItemFamilyId
          ? `cost_item_family_id:${costItemFamilyId}`
          : "",
        modifier: modifier ? `modifier:${modifier}` : "",
      };

      let parsedFilters = Object.values(obj).filter(Boolean).join(",");
      if (parsedFilters) {
        q = `?q=${parsedFilters}`;
      }
      let response = await getRequest(
        "/simulation_modifier_cost_item_family/" + q,
        false
      );
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        let campaignMods = response.data;
        commit("SET_FILTERED_CAMPAIGN_MODIFIERS", campaignMods);
      }
      dispatch("setPageLoadingStatus", false);
    },
    async fetchSliceProductsList({ commit, dispatch }) {
      let sliceProductsList = await getSliceProductsList();
      if (!sliceProductsList.success) {
        dispatch("setNewNotification", sliceProductsList);
      } else {
        sliceProductsList = sliceProductsList.data;
        commit("SET_SLICE_PRODUCTS_LIST", sliceProductsList);
      }
    },
    async fetchSliceCostInputPeriods({ commit, dispatch }) {
      let periods = await getRequest("/cost_input_period");
      if (!periods.success) {
        dispatch("setNewNotification", periods);
      } else {
        periods = periods.data;
        commit("SET_SLICE_COST_INPUT_PERIODS", periods);
      }
    },
    async updateSASliceCache({ commit, dispatch, getters }, key) {
      // Action used to update single activity slice without having to update all of it
      let oldAs = getters.singleActivitySlice;
      let newAs = await updateSingleActivitySliceCache(oldAs, key);
      if (!newAs.success) {
        for (const error of newAs.errorDataArray) {
          dispatch("setNewNotification", error);
        }
      } else {
        commit("SET_SINGLE_ACTIVITY_SLICE", newAs);
      }
    },
    async updateDraftSASliceCache({ commit, dispatch, getters }, key) {
      let oldDAs = getters.singleDraftActivitySlice;
      let newDAs = await updateSingleDraftActivitySliceCache(oldDAs, key);
      if (!newDAs.success) {
        for (const error of newDAs.errorDataArray) {
          dispatch("setNewNotification", error);
        }
      } else {
        commit("SET_SINGLE_DRAFT_ACTIVITY_SLICE", newDAs);
      }
    },
    async fetchCostGroups({ commit, dispatch }) {
      let response = await getRequest("/stored_cost_item_group", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        commit("SET_COST_ITEM_GROUPS", response.data);
      }
    },
    async fetchCostGroupElements({ commit, dispatch }) {
      let response = await getRequest("/stored_cost_item_group_element", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        commit("SET_SINGLE_COST_GROUP_ELEMENTS", response.data);
      }
    },

    async fetchFinancialPolicies({ commit, dispatch }) {
      let response = await getRequest("/payment_policy?limit=1000", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        commit("SET_FINANCIAL_POLICIES", response.data);
      }
    },

    async fetchOtherTonCostItems({ commit, dispatch }) {
      let response = await getRequest("/other_ton_cost_item", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        commit("SET_OTHER_TON_COST_ITEMS", response.data);
      }
    },

    async updateFinancialPolicies({ dispatch }, payload) {
      let response = await putRequest("/payment_policy/" + payload.id, {
        name: payload.name,
        policyJson: JSON.stringify(payload.data),
      });

      console.log(`updateFinancialPolicies ${response.status}`);
      if (!response.success) {
        dispatch("setNewNotification", response);
      } else {
        dispatch("fetchFinancialPolicies");
      }
    },

    async fetchInterestRates({ commit, dispatch }) {
      let response = await getRequest("/interest_rate", false);
      if (!response.success) {
        dispatch("setNewNotification", response);
        throw new Error("Could not fetch interest rates");
      } else {
        let rates = response.data.map((i) => {
          return {
            ...i,
            name: `${i.name} (${i.rate})`,
          };
        });

        rates.push({
          name: "No financiar",
          id: null,
          rate: 0.0,
        });
        commit("SET_INTEREST_RATES", rates);
      }
    },
    async getEngagementResourceUtilization({ commit, dispatch }, { sliceIds }) {
      let masterRU = { rows: [], keys: [] };
      try {
        masterRU = await retrieveMultiResourceUtilization(sliceIds);

        if (!masterRU.success) {
          dispatch("setNewNotification", masterRU);
        } else {
          masterRU = masterRU.data;
          commit("SET_ENGAGEMENT_RESOURCE_UTILIZATION", masterRU);
        }
      } catch {
        let notif = {
          type: "error",
          text: "Fallo al tratar de traer la utilización de recursos",
        };
        dispatch("setNewNotification", notif);
        console.log("GET masterRU failed!");
      }
    },
  },
  modules: {},
});
